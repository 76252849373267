import gql from 'graphql-tag';

export const GetContentTypeQuery = gql`
	query GetContentTypeQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			type
		}
	}
`;

import { getApolloClient } from '@confluence/graphql';

import { GetSpaceNameQuery, GetTemplateInfoQuery } from './queries';
import type {
	GetSpaceNameQuery as GetSpaceNameQueryType,
	GetSpaceNameQueryVariables,
} from './queries/__types__/GetSpaceNameQuery';
import type {
	GetTemplateInfoQuery as GetTemplateInfoQueryType,
	GetTemplateInfoQueryVariables,
} from './queries/__types__/GetTemplateInfoQuery';

const _getSpaceNameQuery = async (
	variables: GetSpaceNameQueryVariables,
): Promise<GetSpaceNameQueryType> => {
	return (
		await getApolloClient().query({
			query: GetSpaceNameQuery,
			variables,
		})
	).data;
};

const _getTemplateInfoQuery = async (
	variables: GetTemplateInfoQueryVariables,
): Promise<GetTemplateInfoQueryType> => {
	return (
		await getApolloClient().query({
			query: GetTemplateInfoQuery,
			variables,
		})
	).data;
};

export const getSpaceName = async (spaceKey: string): Promise<any> => {
	const spaceNameData = await _getSpaceNameQuery({ spaceKey });
	return spaceNameData?.space?.name;
};

export const getTemplateInfo = async (id: any): Promise<any> => {
	const templateData = await _getTemplateInfoQuery({ id });
	return templateData?.templateInfo;
};

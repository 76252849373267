import { ADMIN_TEMPLATES_AND_BLUEPRINTS, SPACE_SETTINGS_TEMPLATES } from '@confluence/named-routes';

export const IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME = 'inEditorTemplatesPanel';
export const IN_EDITOR_TEMPLATES_QUERY_OPEN = 'open';

export const IN_EDITOR_TEMPLATES_QUERY_KEEP_OPEN = 'keep_open';
export const IN_EDITOR_TEMPLATES_QUERY_AUTO_CLOSED = 'auto_closed';

export const CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME = 'createdWithTemplate';

type QueryParamValues =
	| typeof IN_EDITOR_TEMPLATES_QUERY_OPEN
	| typeof IN_EDITOR_TEMPLATES_QUERY_KEEP_OPEN
	| typeof IN_EDITOR_TEMPLATES_QUERY_AUTO_CLOSED;

export const getCreatedWithTemplateQueryParamValue = (getQueryParams) => {
	return getQueryParams()[CREATED_WITH_TEMPLATE_QUERY_PARAM_NAME] === 'true';
};

export const getInEditorTemplatesPanelQueryParamValue = (getQueryParams) => {
	return getQueryParams()[IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME];
};

export const isInEditorTemplatesPanelQueryParamValueOpen = (getQueryParams) => {
	return (
		getInEditorTemplatesPanelQueryParamValue(getQueryParams) === IN_EDITOR_TEMPLATES_QUERY_OPEN
	);
};

export const isInEditorTemplatesPanelQueryParamValueOpenOrKeepOpen = (getQueryParams) => {
	const queryParamValue = getInEditorTemplatesPanelQueryParamValue(getQueryParams);
	return (
		queryParamValue === IN_EDITOR_TEMPLATES_QUERY_OPEN ||
		queryParamValue === IN_EDITOR_TEMPLATES_QUERY_KEEP_OPEN
	);
};

export const removeInEditorTemplatesQueryParam = (setQueryParams) => {
	setQueryParams({ [IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME]: null }, true);
};

export const setInEditorTemplatesPanelQueryParam = (
	setQueryParams,
	queryParamValue: QueryParamValues,
) => {
	setQueryParams({ [IN_EDITOR_TEMPLATES_QUERY_PARAM_NAME]: queryParamValue }, true);
};

export const getTemplateListPage = (spaceKey?: string) => {
	if (spaceKey) {
		return SPACE_SETTINGS_TEMPLATES.toUrl({ spaceKey });
	}

	return ADMIN_TEMPLATES_AND_BLUEPRINTS.toUrl();
};

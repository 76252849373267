import { LoadableAfterPaint } from '@confluence/loadable';

export const TemplatePreview = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TemplatePreview" */ './TemplatePreview'))
			.TemplatePreview,
});

export const PopupPreview = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PopupPreview" */ './PopupPreview')).PopupPreview,
});

export { AdfPreviewRenderer } from './PreviewRenderer';

import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

type CreateCFTMacroAnalyticsEventParams = {
	source?: string;
	type: 'sendTrackEvent' | 'sendUIEvent';
	action: string;
	actionSubject: string;
	actionSubjectId?: string;
	attributes?: Record<string, any>;
};

type CreateCFTMacroAnalyticsEvent = (params: CreateCFTMacroAnalyticsEventParams) => void;

export const useCreateFromTemplateAnalyticsEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createCFTMacroAnalyticsEvent: CreateCFTMacroAnalyticsEvent = useCallback(
		({
			source = 'createFromTemplateMacro',
			type,
			action,
			actionSubject,
			actionSubjectId,
			attributes,
		}) => {
			createAnalyticsEvent({
				type,
				data: {
					source,
					action,
					actionSubject,
					actionSubjectId,
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	return createCFTMacroAnalyticsEvent;
};

import format from 'date-fns/format';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

export const getUserTimezoneDate = ({
	userId,
	isLicensed,
	timeZone,
	dateFormat,
}: {
	userId?: string | null;
	isLicensed?: boolean;
	timeZone?: string | null;
	dateFormat: string;
}) => {
	const isAnonymousUser = !userId || !isLicensed;
	const isLicensedWithTimezone = timeZone && !isAnonymousUser;
	const date = new Date();

	const currentTimezoneDate = isLicensedWithTimezone
		? formatInTimeZone(date, timeZone, dateFormat)
		: format(date, dateFormat);

	return currentTimezoneDate;
};

import { useCallback, useContext } from 'react';

import { CREATE_PAGE_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { getTraceIdFromApolloError } from '@confluence/template-utils';

type UseCreatePageExperienceTrackerResponse = {
	startCreatePageExperience: () => void;
	succeedCreatePageExperience: () => void;
	stopCreatePageExperienceOnError: (error: Error) => void;
};

export const useCreatePageExperienceTracker = (): UseCreatePageExperienceTrackerResponse => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { edition } = useSessionData();

	const startCreatePageExperience = useCallback(() => {
		experienceTracker.start({
			name: CREATE_PAGE_EXPERIENCE,
			attributes: {
				flow: 'createFromTemplateMacro',
				edition,
			},
		});
	}, [experienceTracker, edition]);

	const succeedCreatePageExperience = useCallback(() => {
		experienceTracker.succeed({
			name: CREATE_PAGE_EXPERIENCE,
			attributes: {
				flow: 'createFromTemplateMacro',
			},
		});
	}, [experienceTracker]);

	const stopCreatePageExperienceOnError = useCallback(
		(error: Error) => {
			const cteTraceId = getTraceIdFromApolloError(error);
			if (!cteTraceId) {
				// When the error does not contain trace id, it's presumed that the network call failed due to network issue, thus aborting the experience
				experienceTracker.abort({
					name: CREATE_PAGE_EXPERIENCE,
					reason: `${CREATE_PAGE_EXPERIENCE} aborted due to presumed client network failure`,
					attributes: {
						error: error?.message,
					},
				});
			} else {
				experienceTracker.stopOnError({
					name: CREATE_PAGE_EXPERIENCE,
					error,
					attributes: { cteTraceId },
				});
			}
		},
		[experienceTracker],
	);

	return {
		startCreatePageExperience,
		succeedCreatePageExperience,
		stopCreatePageExperienceOnError,
	};
};

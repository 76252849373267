import gql from 'graphql-tag';

export const GetTemplateInfoQuery = gql`
	query GetTemplateInfoQuery($id: ID!) {
		templateInfo(id: $id) {
			author
			blueprintModuleCompleteKey
			categoryIds
			contentBlueprintId
			description
			hasGlobalBlueprintContent
			hasWizard
			iconURL
			darkModeIconURL
			isFavourite
			isNew
			isPromoted
			itemModuleCompleteKey
			link
			links {
				base
				context
			}
			name
			recommendationRank
			spaceKey
			styleClass
			templateId
			templateType
		}
	}
`;

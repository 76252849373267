import gql from 'graphql-tag';

export const GetSpaceNameQuery = gql`
	query GetSpaceNameQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			name
		}
	}
`;
